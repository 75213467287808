
<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div class="text-sm text-center copyright text-muted text-lg-start">
            <hr/>
            ©
            {{ new Date().getFullYear() }}
            <a
              href="https://zachmatcham.co.uk"
              class="font-weight-bold"
              target="_blank"
              >Zach Matcham</a
            >
          </div>
        </div>
        <div class="col-lg-6">
          <ul
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
<!--            <li class="bg-warning text-black sticky-bottom">Dev mode</li>-->
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
