  <script setup>
import { useRoute } from "vue-router";

import SidenavItem from "./SidenavItem.vue";
import {useAuthStore} from "@/store/AuthStore";

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};

const isAdmin = useAuthStore().isAdmin;
</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          :navText="'Dashboard'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="mt-3 nav-item">
        <h6
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="'ms-2'"
        >
          Sensors
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/sensor/add"
          :class="getRoute() === 'sensor-add' ? 'active' : ''"
          :navText="'Add new sensor'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-fat-add text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
            to="/sensor/manage"
            :class="getRoute() === 'sensor-manage' ? 'active' : ''"
            :navText="'Manage sensors'"
        >
          <template v-slot:icon>
            <i
                class="ni ni-settings-gear-65 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="mt-3 nav-item">
        <h6
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="'ms-2'"
        >
          Alerts
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item
            to="/alerts/conditions"
            :class="getRoute() === 'alerts-conditions' ? 'active' : ''"
            :navText="'Manage alert conditions'"
        >
          <template v-slot:icon>
            <i
                class="ni ni-settings-gear-65 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="mt-3 nav-item">
        <h6
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="'ms-2'"
        >
          Visualise
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item
            to="/visualise/tables/"
            :class="getRoute() === 'visualise-data' ? 'active' : ''"
            :navText="'Tables'"
        >
          <template v-slot:icon>
            <i
                class="ni ni-chart-bar-32 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
            to="/visualise/graphs"
            :class="getRoute() === 'visualise-graphs' ? 'active' : ''"
            :navText="'Graphs'"
        >
          <template v-slot:icon>
            <i
                class="ni ni-chart-bar-32 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="'ms-2'"
        >
          Account
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/account"
          :class="getRoute() === 'account-view' ? 'active' : ''"
          :navText="'My Account'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
            to="/account/billing"
            :class="getRoute() === 'billing' ? 'active' : ''"
            :navText="'Billing'"
        >
          <template v-slot:icon>
            <i class="ni ni-credit-card text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="mt-3 nav-item" v-if="isAdmin">
        <h6
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="'ms-2'"
        >
          Admin
        </h6>
      </li>
      <li class="nav-item" v-if="isAdmin">
        <sidenav-item
            to="/admin"
            :class="getRoute() === 'admin' ? 'active' : ''"
            :navText="'Admin'"
        >
          <template v-slot:icon>
            <i class="ni ni-credit-card text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

    </ul>
  </div>
</template>
