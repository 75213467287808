<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useAxios } from "@/plugins/axios";
import ArgonButton from "@/components/Argon/ArgonButton.vue";
// import GradientLineChart from "@/components/Dashboard/Charts/GradientLineChart.vue";
import GradientGraph from "@/components/Data/GradientGraph.vue";
import { getUnitLabel } from "../../plugins/conversions";
import { useSettingStore } from "../../store/SettingsStore";
import { useToast } from "primevue/usetoast";
// import SensorGraphNew from "@/views/components/SensorGraphNew.vue";
// import SensorTags from "@/views/components/SensorTags.vue";

const axios = useAxios();
const route = useRoute();
const toast = useToast();

const sensor = ref(null);

const sensorName = ref('');
const sensorLocation = ref('');
const sensorStatus = ref('');
const enabled = ref(false);
const sensorTags = ref([]);
const sensorLastSeen = ref('');
const isLoading = ref(true);
const errors = ref({
  sensorName: '',
  sensorLocation: '',
  enabled: '',
  sensorTags: '',
});

const fetchSensor = () => {
  isLoading.value = true;
  try {
    axios.get(`/sensor/get/${route.params.unique_id}`)
        .then((response) => {
          sensor.value = response.data;
          sensorName.value = response.data.name;
          sensorLocation.value = response.data.location;
          sensorStatus.value = response.data.status;
          sensorTags.value = response.data.tags;
          sensorLastSeen.value = response.data.last_seen;
          enabled.value = true; // TODO : Build in API
        });
    fetchSensorData();
  } catch (error) {
    console.log("Failed to fetch sensor: ", error);
    toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch sensor details', life: 3000 });
  } finally {
    isLoading.value = false;

  }
}

const updateSensor = async () => {
  const uniqueId = route.params.unique_id;
  const formData = {
    sensorName: sensorName.value,
    sensorLocation: sensorLocation.value,
    enabled: enabled.value,
  };

  axios.post(`/sensor/update/${uniqueId}`, formData)
      .then((response) => {
        toast.add({ severity: 'success', summary: 'Success', detail: 'Sensor details updated successfully', life: 3000 });
        console.log('Sensor updated: ', response.data);
      })
      .catch((error) => {
        console.error('Failed to update sensor: ', error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to update sensor details', life: 3000 });
  });
};

const tempData = ref([]);
const humData = ref([]);
const chartLabels = ref([]);

const fetchSensorData = async () => {
  try {
    const response = await axios.get(`/sensor/data/last-7`, {
      params: {
        sensorId: route.params.unique_id
      }
    });

    const tempD = [];
    const humD = [];
    const labels = [];

    response.data.forEach(entry => {
      if (entry.data_type === 'Temperature') {
        tempD.push(entry.value);
      } else if (entry.data_type === 'Humidity') {
        humD.push(entry.value);
      }
      labels.push(entry.time);
    });

    tempData.value = tempD;
    humData.value = humD;
    chartLabels.value = [...new Set(labels)];

  } catch (error) {
    console.error('Failed to fetch sensor data', error);
  }
}

// Set form to original state before editing, it needs the original API data // TODO
const resetForm = () => {
  fetchSensor();
  toast.add({ severity: 'info', summary: 'Info', detail: 'Changes to sensor were reset successfully', life: 3000 });
};

const getStatusClass = (status) => {
  if (status === 'online') {
    return 'bg-gradient-success';
  } else if (status === 'offline') {
    return 'bg-gradient-danger';
  } else if (status === 'pending') {
    return 'bg-gradient-warning';
  } else {
    return 'bg-gradient-info';
  }
}

const latestSensorData = ref({})

const getGradientStyle = (value, unit) => {
  // Example gradient calculation based on value (you can customize this logic)
  let color1 = '#ff9999'
  let color2 = '#ffcccc'

  if (unit.includes('°C')) {
    const temp = parseFloat(value)
    if (temp < 20) {
      color1 = '#99ccff'
      color2 = '#cceeff'
    } else if (temp >= 20 && temp <= 25) {
      color1 = '#99ff99'
      color2 = '#ccffcc'
    } else {
      color1 = '#ff9999'
      color2 = '#ffcccc'
    }
  } else if (unit.includes('%')) {
    const hum = parseFloat(value)
    if (hum < 30) {
      color1 = '#ff9999'
      color2 = '#ffcccc'
    } else if (hum >= 30 && hum <= 60) {
      color1 = '#99ff99'
      color2 = '#ccffcc'
    } else {
      color1 = '#99ccff'
      color2 = '#cceeff'
    }
  }

  return {
    background: `linear-gradient(135deg, ${color1}, ${color2})`
  }
}

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp)
  return date.toLocaleString()
}

const fetchLatestSensorData = async () => {
  try {
    const response = await axios.get('/sensor/data/latest', {
      params: { sensorId: route.params.unique_id }
    })
    // Group data by data_type and get the latest entry
    const groupedData = response.data.reduce((acc, data) => {
      const { data_type, time, value, unit } = data;
      if (!acc[data_type] || new Date(acc[data_type].time) < new Date(time)) {
        acc[data_type] = { value, timestamp: time, unit, data_type };
      }
      return acc;
    }, {});

    latestSensorData.value = Object.values(groupedData);
    console.log('Latest sensor data:', latestSensorData.value);
  } catch (error) {
    console.error('Error fetching latest sensor data:', error)
  }
}

onMounted(() => {
  fetchSensor();
  fetchLatestSensorData();
});
</script>
<template>

  <div class="py-4 container-fluid">

    <div class="row">
      <div class="col-12 col-lg-9 mx-auto">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Viewing Sensor: {{ sensorName }} @ {{ sensorLocation }}</h6> <!-- TODO: differentiate text - Allow for location to be toggled -->
          <p class="mb-0 text-sm">View and edit sensor details</p>
        </div>
      </div>
    </div>

    <Toast />

    <div class="row">
      <div class="col-12 col-lg-9 mx-auto">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Current Sensor Data</h6> <!-- TODO: differentiate text -->
          <p class="mb-0 text-sm">View sensor data</p>
          <hr class="my-3 horizontal dark">

          <!-- Latest Sensor Data Card -->
          <div class="latest-sensor-data-card mt-4" v-if="latestSensorData.length">
              <div v-for="(data, index) in latestSensorData" :key="index" class="sensor-data-circle" :style="getGradientStyle(data.value, data.unit)">
                <p class="sensor-data-label mb-4">{{ data.data_type }}</p>
                <p class="sensor-data-value">{{ data.value }} {{ getUnitLabel(data.data_type, useSettingStore().isMetricUnits) }}</p>
                <p class="sensor-data-timestamp">{{ formatTimestamp(data.timestamp) }}</p>
              </div>
          </div>
          <div v-else class="latest-sensor-data-card mt-4 no-data">
            <p>No data available or sensor is offline.</p>
          </div>

          <GradientGraph
              id="lastSevenChart"
              :height="300"
              v-if="tempData.length && humData.length"

              :temperatureData="tempData"
              :humidityData="humData"
              :labels="chartLabels"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Edit Sensor Details</h6>
          <p class="mb-0 text-sm">Changing settings of {{ sensorName }}</p>
          <hr class="my-3 horizontal dark">

          <!-- Loading Spinner -->
          <transition name="fade">
            <div v-if="isLoading" class="loading-overlay d-flex justify-content-center align-items-center">
              <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </transition>

          <!-- Sensor Details Form -->
          <form :class="{ 'opacity-50': isLoading }" @submit.prevent="updateSensor">
            <!-- Sensor Name -->
            <label for="sensorName" class="form-label">Sensor Name <span class="required">*</span></label>
            <input id="sensorName" type="text" class="form-control" v-model="sensorName">
            <div v-if="errors.sensorName" class="text-danger">{{ errors.sensorName }}</div>

            <!-- Sensor Location -->
            <label for="sensorLocation" class="form-label mt-4">Sensor Location</label>
            <input id="sensorLocation" type="text" class="form-control" v-model="sensorLocation">
            <div v-if="errors.sensorLocation" class="text-danger">{{ errors.sensorLocation }}</div>


            <!-- Status Indicator -->
<!--            <div class="d-flex mt-4">-->
              <h6 class="form-label mt-4">Status</h6>
              <div class="align-left text-sm" style="margin-left: 0.3em;">
                <span class="badge badge-sm" :class="getStatusClass(sensorStatus)">{{ sensorStatus }}</span>
              </div>
<!--            </div>-->

            <label for="sensorLastSeen" class="form-label mt-4">Last Seen</label>
            <input id="sensorLastSeen" type="text" class="form-control" disabled :value="sensorLastSeen">

            <!-- Sensor ID (not submittable) - TODO : Big type or premade config? -->
            <label for="sensorUniqueId" class="form-label mt-4">Registration Code</label>
            <input id="sensorUniqueId" type="text" class="form-control" disabled :value="route.params.unique_id">

            <!-- Enabled Switch -->
            <div class="mt-4 row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label> Enabled? </label>
                  <p class="text-xs form-text text-muted ms-1">
                    If you want this sensor to not be enabled, don't tick
                  </p>
                  <div class="form-check form-switch ms-1">
                    <input id="enabled-switch" class="form-check-input" type="checkbox" v-model="enabled">
                    <label class="form-check-label" for="enabled-switch"></label>
                  </div>
                </div>
              </div>
            </div>




            <!-- Sensor Tags - Disabled TODO -->
<!--            <label class="mt-4 form-label">Sensor Tags</label>-->
<!--            <div v-if="!isLoading">-->
<!--              <SensorTags :tags="sensorTags" :sensor-id="route.params.unique_id"/>-->
<!--            </div>-->


            <!-- Buttons -->
            <div class="mt-4 d-flex justify-content-end">
              <ArgonButton type="button" name="cancelBtn" class="m-0 btn btn-light" @click="resetForm"> Cancel</ArgonButton>
              <ArgonButton type="submit" name="submitBtn" class="m-0 btn bg-gradient-success ms-2"> Save Changes</ArgonButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.opacity-50 {
  opacity: 0.5;
}

.latest-sensor-data-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.sensor-data-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  text-align: center;
}

.sensor-data-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.sensor-data-label {
  font-size: 1rem;
  color: #666;
}

.sensor-data-timestamp {
  font-size: 0.8rem;
  color: #a5a5a5;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  color: #666;
}
</style>