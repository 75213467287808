// store/AuthStore.js
import { defineStore } from 'pinia';
import axios from "axios";
import { jwtDecode } from 'jwt-decode';

export const useAuthStore = defineStore({
    id: 'auth', // Unique store ID
    state: () => ({
        user_token: '', // Only store the token
        user_loggedIn: false,
        token_timestamp: '', // Store timestmap of token to refresh
        user: {},
        refreshToken: '',
    }),
    actions: {
        login(token, refreshToken) {
            console.log('login: ', refreshToken);
            this.user_token = token;
            this.refreshToken = refreshToken;
            this.user_loggedIn = true;
            this.getUserInfo(); // Ignore promise as we don't need to wait for it
            // this.startRefreshTimer(); // Start refresh timer
        },
        async getUserInfo() {
            try {
                const response = await axios.get( process.env.VUE_APP_API_URL + '/v1/user/info', {
                    headers: {
                        'Authorization': `Bearer ${this.user_token}`
                    }
                });
                this.user = response.data;
            } catch (error) {
                console.log("Error fetching user info: ", error);
            }
        },
        async refreshAccessToken() {
          try {
              const response = await axios.post( process.env.VUE_APP_API_URL + '/token/refresh',
                  {
                      'refresh_token': this.refreshToken
                  })
              this.user_token = response.data.token;
              this.refreshToken = response.data.refresh_token;
              this.token_timestamp = jwtDecode(response.data.token).exp;
              console.log("Refreshed token" + this.token_timestamp);
          } catch (error) {
              console.log("Error refreshing token: ", error); // TODO : Error feedback to user
          }
        },
        logout() {
            this.user_token = '';
            this.user_loggedIn = false;
            this.refreshToken = '';
            this.user = {};
        },

    },
    getters: {
        isAuthenticated: (state) => !!state.user_token,
        getUserToken: (state) => state.user_token,
        getUser: (state) => state.user,
        getRefreshToken: (state) => state.refreshToken,
        isAdmin: (state) => {
            if (state.user.roles) {
                return state.user.roles.includes('ROLE_ADMIN'); // As per API roles
            }
        }
    },
    persist: true // Persist this to LS
});

