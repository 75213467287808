import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Billing from "../views/Account/Billing.vue";
import Profile from "../views/Account/Profile.vue";
import Signup from "../views/Auth/Signup.vue";
import Signin from "../views/Auth/Signin.vue";

import { useAuthStore } from "@/store/AuthStore";

import NotFound from "@/views/Errors/NotFound.vue";
import NewSensor from "@/views/Sensors/NewSensor.vue";
import ListSensors from "@/views/Sensors/ListSensors.vue";
import Tables from "@/views/Visualise/Tables.vue";
import Graphs from "@/views/Visualise/Graphs.vue";
import GetStarted from "@/views/Account/GetStarted.vue";
import ViewSensor from "@/views/Sensors/ViewSensor.vue";

import NProgress from "nprogress";

const routes = [
  { // Error routes
    path: '/:pathMatch(.*)*',
    component: NotFound
  },
  { // Redirect / to dash
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  { // Dash
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  { // Sensor Routes
    path: "/sensor/add",
    name: "Add new sensor",
    component: NewSensor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/sensor/manage",
    name: 'Manage Sensors',
    component: ListSensors,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/sensor/manage/:unique_id",
    name: 'Edit Sensor',
    component: ViewSensor,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  { // Visualise routes
    path: "/visualise/tables",
    name: "Tables",
    component: Tables,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/visualise/graphs",
    name: "Graphs",
    component: Graphs,
    meta: {
      requiresAuth: true
    }
  },
  { // Account routes
    path: "/account/billing",
    name: "Billing",
    component: Billing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/account",
    name: "My Account",
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/account/get-started",
    name:"Get Started",
    component: GetStarted,
    meta: {
      requiresAuth: true
    }
  },
  { // Alert routes
    path: "/alerts/conditions",
    name: "Alert Conditions",
    component: () => import('@/views/Alerts/AlertConditions.vue'),
  },
  { // Auth rotues
    path: "/signin", // TODO : Change path? /auth/login?
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: (to, from, next) => { // Check if they're acutally logged in first
      if (!useAuthStore().isAuthenticated) {
        next('/signin');
      } else {
        useAuthStore().logout();
        next('/signin');
      }
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Dashboard,
    meta: {
        requiresAdmin: true,
        // requiresAuth: true
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Change to import.meta for Vite, process for Vue-cli
  routes,
  linkActiveClass: "active",
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach( () => {
  NProgress.done();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check for requiresAuth meta
    if (useAuthStore().isAuthenticated) {
      next();
    } else {
      next ('/signin');
    }
  } else if (to.matched.some((record) => record.name === 'Signin') && useAuthStore().isAuthenticated) {
    // Don't allow a user to access login if authenticated
    next('/dashboard');
  } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
    // Check if user is admin (TODO : do we need added checks or is local storage var enough)
    if (useAuthStore().isAdmin) {
      next();
    } else {
      next('/404'); // TODO : Unauthorised route
    }
  } else {
      next();
  }
})

export default router;
