<script setup>
import { ref, onMounted } from 'vue';
import { useAxios } from "@/plugins/axios";
import ArgonButton from "@/components/Argon/ArgonButton.vue";

const axios = useAxios();

const sensors = ref([]);
const isLoading = ref(true);

const fetchSensors = async () => {
  isLoading.value = true;
  try {
    const response = await axios.get('/sensors/list');
    sensors.value = response.data;
  } catch (error) {
    console.log("Failed to fetch sensors: ", error);
  } finally {
    setTimeout(() => {
      isLoading.value = false;
    }, 450); // Make it a bit slower ;)
  }
}

const getStatusClass = (status) => {
  if (status === 'online') {
    return 'bg-gradient-success';
  } else if (status === 'offline') {
    return 'bg-gradient-danger';
  } else if (status === 'pending') {
    return 'bg-gradient-warning';
  } else {
    return 'bg-gradient-info';
  }
}

onMounted(() => {
  fetchSensors();
});

// onMounted(async () => {
//   try {
//     const response = await fetch('https://jsonplaceholder.typicode.com/users');
//     sensors.value = await response.json();
//   } catch (error) {
//     console.log("Failed to fetch sensors: ", error);
//   }
// });

</script>

<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Your Sensors</h6>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <!-- Loading Spinner -->
      <transition name="fade">
        <div v-if="isLoading" class="card loading-overlay d-flex justify-content-center align-items-center">
          <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </transition>
<!--      <transition name="fade">-->
      <div :class="{ 'opacity-50': isLoading }" class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
          <tr>
            <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Sensor Name
            </th>
            <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Location
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Status
            </th>
            <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Current Data
            </th>
            <th class="text-secondary opacity-7"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="sensor in sensors" :key="sensor.id">
            <td>
              <div class="d-flex px-2 py-1">
                <div>
                  <img
                      src="../../assets/img/sensor_icon.png"
                      class="avatar avatar-sm me-3"
                      alt="Sensor Icon"
                  />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ sensor.name }}</h6>
                  <p class="text-xs text-secondary mb-0">
                    ID: {{ sensor.unique_id }}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{ sensor.location }}</p>
              <p class="text-xs text-secondary mb-0">{{ sensor.location }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="badge badge-sm" :class="getStatusClass(sensor.status)">{{ sensor.status }}</span>
            </td>
            <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                >N/A</span
                >
            </td>
            <td class="align-middle">
              <router-link :to="{ name: 'Edit Sensor', params: { unique_id: sensor.unique_id } }">
                <ArgonButton class="btn-outline-default font-weight-bold text-xs">Edit</ArgonButton>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
<!--      </transition>-->

    </div>
  </div>
</template>


<style scoped>

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.opacity-50 {
  opacity: 0.5;
}
</style>