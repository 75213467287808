<template>
  <div>
    <v-chart :option="chartOptions" style="width: 100%; height: 400px;"></v-chart>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from 'vue';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, LegendComponent, TitleComponent } from 'echarts/components';
import VChart from 'vue-echarts';

// Manually register required components
use([
  CanvasRenderer,
  LineChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent
]);

export default defineComponent({
  name: 'GradientChart',
  components: {
    VChart
  },
  props: {
    temperatureData: {
      type: Array,
      required: true
    },
    humidityData: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const chartOptions = ref(null);

    const setChartOptions = () => {
      chartOptions.value = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Temperature', 'Humidity']
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: props.labels,
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Temperature',
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#FF6347'
            },
            lineStyle: {
              width: 3
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(203, 12, 159, 0.2)', // Gradient color 1
                  },
                  {
                    offset: 1,
                    color: 'rgba(203, 12, 159, 0)', // Gradient color 2
                  },
                ],
              },
            },
            data: props.temperatureData
          },
          {
            name: 'Humidity',
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#87CEEB'
            },
            lineStyle: {
              width: 3
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(203, 12, 159, 0.2)', // Gradient color 1
                  },
                  {
                    offset: 1,
                    color: 'rgba(203, 12, 159, 0)', // Gradient color 2
                  },
                ],
              },
            },
            data: props.humidityData
          }
        ]
      };
    };

    onMounted(() => {
      setChartOptions();
    });

    watch(() => [props.temperatureData, props.humidityData, props.labels], setChartOptions);

    return {
      chartOptions
    };
  }
});
</script>

<style scoped>
/* Add your styles here */
</style>
