  <script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useAxios } from "@/plugins/axios";
  import { useMainStore } from "@/store/MainStore";
  import dayjs from 'dayjs';
  import relativeTime from 'dayjs/plugin/relativeTime';

  dayjs.extend(relativeTime);

  const axios = useAxios();
  const store = useMainStore();

  const showMenu = ref(false);
  const notifications = ref([]);
  const darkMode = computed(() => store.darkMode);
  const unreadCount = computed(() => notifications.value.length);

  const getBackgroundClass = (severity) => {
    return `bg-gradient-${severity || 'secondary'}`;
  };

  const toggleMenu = () => showMenu.value = !showMenu.value;
  const closeMenu = () => {
    setTimeout(() => {
      showMenu.value = false;
    }, 100);
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get('/user/notifications');
      notifications.value = response.data
          .filter(notification => !notification.is_read)
          .map(notification => ({
        ...notification,
        timeAgo: dayjs(notification.created_at.date).fromNow()
      }));
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const clearNotification = async (id) => {
    try {
      await axios.post(`/user/notifications/${id}/clear`);
      notifications.value = notifications.value.filter(n => n.id !== id);
    } catch (error) {
      console.error('Error clearing notification:', error);
    }
  };

  onMounted(() => {
    fetchNotifications();
  });
  </script>

  <template>
    <li class="nav-item dropdown d-flex align-items-center pe-2">
      <a
          href="#"
          class="p-0 nav-link"
          :class="[showMenu ? 'show' : ''] [darkMode ? 'text-white' : 'text-dark']"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          @click="toggleMenu"
          @blur="closeMenu"
      >
        <i class="cursor-pointer fa fa-bell" :class="darkMode ? 'text-white' : 'text-dark'"></i>
        <span class="badge badge-sm badge-circle badge-floating badge-primary border-white">{{ unreadCount }}</span>
      </a>
      <ul
          class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
          :class="showMenu ? 'show' : ''"
          aria-labelledby="dropdownMenuButton"
      >
        <li v-if="notifications.length === 0" class="text-center text-muted py-2">
          Nothing to see here
        </li>
        <li v-for="notification in notifications" :key="notification.id" class="mb-2">
          <div class="dropdown-item border-radius-md">
            <div class="py-1 d-flex align-items-center">
              <div class="my-auto avatar avatar-sm me-3" :class="getBackgroundClass(notification.severity)">
                <i :class="notification.icon"></i>
              </div>
              <div class="d-flex flex-column justify-content-center me-3">
                <h6 class="mb-1 text-sm font-weight-normal">
                  {{ notification.message }}
                </h6>
                <p class="mb-0 text-xs text-secondary">
                  <i class="fa fa-clock me-1"></i>
                  {{ notification.timeAgo }}
                </p>
              </div>
              <button @click.stop="clearNotification(notification.id)" v-if="!notification.is_global" class="btn btn-link text-danger p-0 ms-auto">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </li>
  </template>

  <style scoped>
  .dropdown-item {
    display: flex;
    justify-content: space-between;
  }

  .dropdown-item .avatar {
    min-width: 2rem;
    min-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-link {
    margin-left: auto;
  }

  .py-1.d-flex {
    display: flex;
    align-items: center;
  }
  </style>