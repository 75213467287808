<template>
  <div>
    <v-chart :option="chartOptions" style="height: 400px; width: auto;"></v-chart>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from 'vue';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, LegendComponent, TitleComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';

// Register ECharts components and renderers
use([CanvasRenderer, LineChart, GridComponent, TooltipComponent, LegendComponent, TitleComponent]);

export default defineComponent( {
  name: 'DataGraph',
  components: {
    VChart,
  },
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const chartOptions = ref(null);

    const setChartOptions = () => {
      if (!props.datasets || !props.labels) {
        // Handle cases where datasets or labels are not yet available
        return;
      }
      const series = props.datasets.map((dataset) => ({
        name: dataset.label,
        type: 'line',
        smooth: true,
        data: dataset.data,
        lineStyle: {
          width: 3,
          color: getRandomColor(),
        },
        itemStyle: {
          color: getRandomColor(),
        },
      }));

      chartOptions.value = {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: props.datasets.map(dataset => dataset.label),
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: props.labels,
        },
        yAxis: {
          type: 'value',
        },
        series: series,
      };
      console.log('Chart options set:', chartOptions.value);
    };

    const getRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    onMounted(() => {
      setChartOptions();
      console.log(chartOptions.value);
    });

    watch(() => [props.datasets, props.labels], setChartOptions);

    return {
      chartOptions
    };
  },
});

</script>

<style scoped>
/* Add your styles here */
</style>
