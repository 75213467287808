<script setup>

import { computed, onMounted, ref } from 'vue';
import ArgonInput from "@/components/Argon/ArgonInput.vue";
import ArgonButton from "@/components/Argon/ArgonButton.vue";
// import SensorChart from "@/views/components/SensorChart.vue";
import { useAxios } from "@/plugins/axios";
// import SensorGraphNew from "@/views/components/SensorGraphNew.vue";
import DataGraph from "@/components/Data/DataGraph.vue";

const axios = useAxios();

const sensorData = ref([]);
const isLoading = ref(false);
const isChartShowing = ref(false);
const formData = ref({
  startDate: '',
  endDate: '',
  dataTypes: []
});


// Nicked from SensorSelectionModal.vue
const visible = ref(false);
const searchTerm = ref('');
const selectedSensors = ref([]);
const allSensors = ref([
// This will all be fetched when mounted
]);

const dialogVisible = ref(false);
const dialogMsg = ref('');

const filteredSensors = computed(() => {
  return allSensors.value.filter(sensor =>
      sensor.name.toLowerCase().includes(searchTerm.value.toLowerCase()) // Do we need to check if is hidden or invalid status? TODO
  );
});

const sensorIdToNameMap = ref({});

const fetchData = async () => {
  if (selectedSensors.value.length === 0) {
    dialogMsg.value = 'Please select at least one sensor';
    dialogVisible.value = true;
    return;
  }
  isLoading.value = true;
  try {
    const response = await axios.get('/sensor/data', {
      params: {
        sensorIds: selectedSensors.value,
        startDate: formData.value.startDate,
        endDate: formData.value.endDate,
        dataTypes: formData.value.dataTypes
      },
    });
    if (response.data.length === 0) {
      dialogMsg.value = 'No data available for the selected sensors and date range.';
      dialogVisible.value = true;
      return;
    }
    sensorData.value = response.data.map(item => ({
      ...item,
      sensor_name: sensorIdToNameMap.value[item.sensor_id] || item.sensor_id
    }));
    processChartData();
  } catch (error) {
    console.log("Failed to fetch sensor data: ", error);
  } finally {
    setTimeout(() => {
      isLoading.value = false;
      isChartShowing.value = true; // Need a delay otherwise will try and render before data is loaded
      // TODO : Don't show the chart if there is no data
    }, 700);
  }
}

const processedData = ref({
  labels: [],
  datasets: [],
});

const processChartData = () => {
  const dataByType = {};
  sensorData.value.forEach(item => {
    const key = `${item.sensor_name}-${item.data_type}`;
    if (!dataByType[key]) {
      dataByType[key] = {
        label: `${item.sensor_name} - ${item.data_type}`,
        data: [],
        borderColor: getRandomColor(),
        fill: false,
      };
    }
    dataByType[key].data.push(item.value);
  });
  processedData.value = {
    labels: sensorData.value.map(item => item.time),
    // datasets: Object.values(dataByType),
    datasets: Object.values(dataByType).map(dataset => ({
      label: dataset.label,
      data: dataset.data,
      borderColor: dataset.borderColor,
      fill: dataset.fill,
    })),
  };
};

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

onMounted(() => {
  axios.get('/sensors/list')
      .then(response => {
        allSensors.value = Object.values(response.data);
        sensorIdToNameMap.value = allSensors.value.reduce((acc, sensor) => {
          acc[sensor.unique_id] = sensor.name;
          return acc;
        }, {});
      })
      .catch(error => {
        console.log("Failed to fetch sensors: ", error);
      });
});

// const getSensorName = (uniqueId) => {
//   const sensor = allSensors.value.find(s => s.unique_id === uniqueId);
//   return sensor ? sensor.name : uniqueId;
// };


</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <h6>Sensor Data</h6>
          </div>

          <div class="card-body px-0 pt-0 pb-2">
            <!-- Loading Spinner -->
            <!-- Note to self: If page transitions fuck up, its because its outside the div -->
            <transition name="fade">
              <div v-if="isLoading" class="card loading-overlay d-flex justify-content-center align-items-center">
                <div class="spinner-border text-warning" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </transition>

            <DialogView v-model:visible="dialogVisible" header="Warning" modal>
              <p>{{ dialogMsg }}</p>
              <ArgonButton @click="dialogVisible = false" class="btn btn-primary btn-block">OK</ArgonButton>
            </DialogView>

            <!--      <SensorSelectionModal />-->
            <div>
              <div class="card flex justify-content-center">
                <DialogView v-model:visible="visible" modal header="Choose Sensors" :style="{ width: '25rem' }">
                  <span class="p-text-secondary block mb-5">Select sensors to get data from</span>
                  <div class="flex align-items-center gap-3 mb-5">
                    <input v-model="searchTerm" placeholder="Search sensors" class="form-control mb-3">
                    <!-- Multiple select options -->
                    <select multiple v-model="selectedSensors" class="form-select">
                      <option v-for="sensor in filteredSensors" :key="sensor.id" :value="sensor.unique_id">{{ sensor.name }}</option>
                    </select>
                  </div>
                  <div class="flex justify-content-end gap-2">
                    <Button type="button" class="btn btn-secondary mx-2" @click="visible = false">Close</Button>
                    <!--              <Button type="button" class="btn btn-primary">Save changes</Button>-->
                  </div>
                </DialogView>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-3">
              <div class="d-flex flex-column">
                <label for="startDate" class="form-label">Start Date</label>
                <ArgonInput type="datetime-local" v-model="formData.startDate" label="Start Date" required/>
                <label for="endDate" class="form-label">End Date</label>
                <ArgonInput type="datetime-local" v-model="formData.endDate" label="End Date" required/>
              </div>

              <div class="d-flex flex-column mx-3">
                <label for="startDate" class="form-label">Data Types</label>
                <div class="form-check">
                  <input
                      id="temperature"
                      class="form-check-input"
                      type="checkbox"
                      value="temperature"
                      v-model="formData.dataTypes"
                  />
                  <label for="temperature" class="custom-control-label">
                    Temperature
                  </label>
                </div>
                <div class="form-check">
                  <input
                      id="humidity"
                      class="form-check-input"
                      type="checkbox"
                      value="humidity"
                      v-model="formData.dataTypes"
                  />
                  <label for="temperature" class="custom-control-label">
                    Humidity
                  </label>
                </div>
                <div class="form-check">
                  <input
                      id="pressure"
                      class="form-check-input"
                      type="checkbox"
                      value="pressure"
                      v-model="formData.dataTypes"
                  />
                  <label for="temperature" class="custom-control-label">
                    Pressure
                  </label>
                </div>
                <div class="form-check">
                  <input
                      id="co2"
                      class="form-check-input"
                      type="checkbox"
                      value="co2"
                      v-model="formData.dataTypes"
                  />
                  <label for="temperature" class="custom-control-label">
                    CO2
                  </label>
                </div>
              </div>

              <div class="d-flex flex-column mx-3 justify-content-center">
                <Button @click="visible = true" class="btn btn-primary btn-block">Choose Sensors</Button>
                <ArgonButton @click="fetchData" class="btn btn-primary btn-block">Fetch Data</ArgonButton>
              </div>
            </div>

            <!-- Graph -->
            <div :class="{ 'opacity-50': isLoading }" class="p-0">
<!--              <SensorGraphNew v-if="isChartShowing" :chartData="sensorData" id="sensorGraph"/>-->
              <DataGraph :datasets="processedData.datasets" :labels="processedData.labels" v-if="isChartShowing" id="test"></DataGraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.opacity-50 {
  opacity: 0.5;
}
</style>