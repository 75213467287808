<script setup>
import { useSettingStore } from '@/store/SettingsStore';
import { useAxios } from '@/plugins/axios';
import ArgonButton from '@/components/Argon/ArgonButton.vue';

const settings = useSettingStore();
const axios = useAxios();

settings.fetchUserSettings();

const submitForm = async () => {
  const settingsArray = {
    dark_mode: settings.darkMode,
    units: settings.metricUnits ? 'metric' : 'imperial',
    notify_by_email: settings.notifyByEmail,
    notify_by_sms: settings.notifyBySMS,
    notify_by_push: settings.notifyByPush,
  };

  try {
    const response = await axios.post('/user/settings', settingsArray);
    console.log('Submitted settings', response.data);
  } catch (error) {
    console.error('Error submitting settings', error);
  }
};
</script>

<template>
  <div class="card">
    <div class="card-header pb-0 p-3">
      <h6 class="mb-0">Settings</h6>
    </div>
    <div class="card-body p-3">
      <div class="form-group d-flex align-items-center justify-content-between">
        <span class="text-sm">Dark Mode</span>
        <div class="form-check form-switch ps-0 ms-3">
          <input id="toggle-dark-mode" class="form-check-input ms-0" type="checkbox" v-model="settings.darkMode" />
          <label class="form-check-label" for="toggle-dark-mode"></label>
        </div>
      </div>
      <div class="form-group d-flex align-items-center justify-content-between">
        <span class="text-sm">Metric</span>
        <div class="form-check form-switch ps-0 ms-3">
          <input id="toggle-metric-units" class="form-check-input ms-0" type="checkbox" v-model="settings.metricUnits" />
          <label class="form-check-label" for="toggle-metric-units"></label>
        </div>
      </div>
      <div class="form-group d-flex align-items-center justify-content-between">
        <span class="text-sm">Notify by Email</span>
        <div class="form-check form-switch ps-0 ms-3">
          <input id="toggle-notify-email" class="form-check-input ms-0" type="checkbox" v-model="settings.notifyByEmail" />
          <label class="form-check-label" for="toggle-notify-email"></label>
        </div>
      </div>
      <div class="form-group d-flex align-items-center justify-content-between">
        <span class="text-sm">Notify by SMS</span>
        <div class="form-check form-switch ps-0 ms-3">
          <input id="toggle-notify-sms" class="form-check-input ms-0" type="checkbox" v-model="settings.notifyBySMS" />
          <label class="form-check-label" for="toggle-notify-sms"></label>
        </div>
      </div>
      <div class="form-group d-flex align-items-center justify-content-between">
        <span class="text-sm">Notify by Push</span>
        <div class="form-check form-switch ps-0 ms-3">
          <input id="toggle-notify-push" class="form-check-input ms-0" type="checkbox" v-model="settings.notifyByPush" />
          <label class="form-check-label" for="toggle-notify-push"></label>
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="row mt-2">
        <div class="col-lg-8 col-12 actions text-end ms-auto d-i">
          <ArgonButton type="button" class="btn-danger mb-2">Cancel</ArgonButton>
          <ArgonButton type="button" style="margin-left: 0.5em;" class="mb-2" @click="submitForm">Save Changes</ArgonButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add any scoped styles here */
</style>
