// MainStore.js
import { defineStore } from 'pinia';

export const useMainStore = defineStore('main', {
    state: () => ({
        hideConfigButton: true,
        isPinned: false,
        showConfig: false,
        sidebarType: "bg-white",
        mcolor: "",
        darkMode: false,
        isNavFixed: true,
        isAbsolute: false,
        showNavs: true,
        showSidenav: true,
        showNavbar: true,
        showFooter: true,
        showMain: true,
        layout: "default",
    }),
    actions: {
        toggleConfigurator() {
            this.showConfig = !this.showConfig;
        },
        sidebarMinimize() {
            let sidenav_show = document.querySelector("#app");
            if (this.isPinned) {
                sidenav_show.classList.add("g-sidenav-hidden");
                sidenav_show.classList.remove("g-sidenav-pinned");
                this.isPinned = false;
            } else {
                sidenav_show.classList.add("g-sidenav-pinned");
                sidenav_show.classList.remove("g-sidenav-hidden");
                this.isPinned = true;
            }
        },
        toggleSidebarColor(payload) {
            this.sidebarType = payload;
        },
        navbarFixed() {
            this.isNavFixed = !this.isNavFixed;
        },
    },
    getters: {
    },
});
