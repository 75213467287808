<script setup>
import { computed } from "vue";
import { useMainStore } from "@/store/MainStore.js";
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/calid_logo_small.png";
import logoWhite from "@/assets/img/calid_logo_small.png";

const store = useMainStore();
const layout = computed(() => store.layout);
const sidebarType = computed(() => store.sidebarType);
const darkMode = computed(() => store.darkMode);
</script>
<template>
  <div
    v-show="layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${darkMode ? 'bg-transparent' : 'bg-gradient-calid'}`"
  />

  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl fixed-start ms-3"
    :class="`
      ${
        layout === 'landing' ? 'bg-transparent shadow-none' : ' '
      } ${sidebarType}`"
    id="sidenav-main" data-color="warning"
  >
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>

      <router-link class="m-0 navbar-brand" to="/">
        <img
          :src="darkMode || sidebarType === 'bg-default' ? logoWhite : logo"
          class="navbar-brand-img h-100"
          alt="Calid Logo"
        />

        <span class="ms-2 font-weight-bold me-2">Calid</span>
      </router-link>
    </div>

    <hr class="mt-0 horizontal dark" />

    <sidenav-list />
    <div class="py-3 align-items-center justify-content-lg-between mb-4 mb-lg-0">
    <hr/>
    <div class="text-sm text-center copyright text-muted text-lg-start px-3">
      © {{ new Date().getFullYear() }}
      <a href="https://zachmatcham.co.uk" class="font-weight-bold" target="_blank">
        Zach Matcham
      </a>
    </div>
    </div>

  </aside>
</template>
