<script setup>

</script>

<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-7">
        <div class="text-center">
          <img src="/img/illustrations/404-error.svg" class="img-fluid" alt="404 error" />
          <h1 class="display-1">404</h1>
          <p class="lead">Page
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>