export function celsiusToFahrenheit(celsius) {
    return (celsius * 9/5) + 32;
}

export function fahrenheitToCelsius(fahrenheit) {
    return (fahrenheit - 32) * 5/9;
}

export function getUnitLabel(dataType, isMetricUnits) {
    const unitMap = {
        temperature: isMetricUnits ? '°C' : '°F',
        humidity: '%',
        pressure: isMetricUnits ? 'hPa' : 'inHg',
        co2: 'ppm'
    };
    return unitMap[dataType.toLowerCase()] || ''; // data type is set to lower case to avoid case sensitivity
}

export function convertValue(dataType, value, isMetricUnits) {
    if (dataType.toLowerCase() === 'temperature' && !isMetricUnits) {
        return celsiusToFahrenheit(value).toFixed(1);
    }
    return value;
}