<script setup>
// import { computed } from "vue";
import { useMainStore } from "@/store/MainStore";

const store = useMainStore();
const sidebarMinimize = () => store.sidebarMinimize();

const minimizeSidebar = () => {
  if (window.innerWidth < 1200) {
    sidebarMinimize();
  }
};

defineProps({
  to: {
    type: String,
    required: true,
  },
  navText: {
    type: String,
    required: true,
  },
});
</script>
<template>
  <router-link :to="to" class="nav-link" @click="minimizeSidebar">
    <div
      class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
    >
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text" :class="'ms-1'">{{
      navText
    }}</span>
  </router-link>
</template>
