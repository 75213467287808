<script setup>
import { ref, onMounted, computed } from 'vue';
import { useAxios } from "@/plugins/axios";

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ArgonInput from "@/components/Argon/ArgonInput.vue";
import ArgonButton from "@/components/Argon/ArgonButton.vue";
import Dropdown from 'primevue/dropdown';
import { useToast } from 'primevue/usetoast';
import { useSettingStore } from "@/store/SettingsStore";
import { convertValue, getUnitLabel } from '@/plugins/conversions';
// import MultiSelect from "primevue/multiselect";

const axios = useAxios();
const toast = useToast();

// Moved Modal into here instead of seperate component, as this requires it and makes life easier

const sensorData = ref([]);
const isLoading = ref(false);
const formData = ref({
  startDate: '',
  endDate: '',
  dataTypes: []
});

const selectedFormat = ref(null);
const downloadFormats = [
  { label: 'CSV', value: 'csv' },
  { label: 'XLSX', value: 'xlsx' },
];

const downloadData = async () => {
  if (!selectedFormat.value) {
    dialogMsg.value = 'Please select a format to download data in';
    dialogVisible.value = true;
    return;
  }
  console.log('Downloading data in format:', selectedFormat.value);
  const response = await axios.get('/sensor/export', {
    params: {
      startDate: formData.value.startDate,
      endDate: formData.value.endDate,
      dataTypes: formData.value.dataTypes,
      format: selectedFormat.value,
      sensorIds: selectedSensors.value,
    },
  });
  if (response.data.download_url) {
    console.log("Data exported successfully");
    // Show toast notification with download link
    toast.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Data exported successfully',
      life: 3000,
    });
    // Do dl for user
    const link = document.createElement('a');
    link.href = response.data.download_url;
    link.download = `sensor-data.${selectedFormat.value}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    console.error("Failed to download data");
  }
};

// Nicked from SensorSelectionModal.vue
const visible = ref(false);
const searchTerm = ref('');
const selectedSensors = ref([]);
const allSensors = ref([
// This will all be fetched when mounted
]);

const dialogVisible = ref(false);
const dialogMsg = ref('');

const filteredSensors = computed(() => {
  return allSensors.value.filter(sensor =>
      sensor.name.toLowerCase().includes(searchTerm.value.toLowerCase()) // Do we need to check if is hidden or invalid status? TODO
  );
});

const fetchData = async () => {
  if (selectedSensors.value.length === 0) {
    dialogMsg.value = 'Please select at least one sensor';
    dialogVisible.value = true;
    return;
  }

  isLoading.value = true;
  console.log(formData.value.dataTypes);
  try {
    const response = await axios.get('/sensor/data', {
      params: {
        sensorIds: selectedSensors.value,
        startDate: formData.value.startDate,
        endDate: formData.value.endDate,
        dataTypes: formData.value.dataTypes
      },
    });
    if (response.data.length === 0) {
      dialogMsg.value = 'No data available for the selected sensors and date range.';
      dialogVisible.value = true;
      return;
    }
    sensorData.value = response.data;
  } catch (error) {
    console.log("Failed to fetch sensor data: ", error);
  } finally {
    setTimeout(() => {
      isLoading.value = false;
    }, 700);
  }
}

onMounted(() => {
  axios.get('/sensors/list')
      .then(response => {
        allSensors.value = Object.values(response.data);
      })
      .catch(error => {
        console.log("Failed to fetch sensors: ", error);
      });
});

const getSensorName = (uniqueId) => {
  const sensor = allSensors.value.find(s => s.unique_id === uniqueId);
  return sensor ? sensor.name : uniqueId;
};

</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <h6>Sensor Data</h6>
          </div>
          <Toast />
          <div class="card-body px-0 pt-0 pb-2">
            <!-- Loading Spinner -->
            <transition name="fade">
              <div v-if="isLoading" class="card loading-overlay d-flex justify-content-center align-items-center">
                <div class="spinner-border text-warning" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </transition>

            <DialogView v-model:visible="dialogVisible" header="Warning" modal>
              <p>{{ dialogMsg }}</p>
              <ArgonButton @click="dialogVisible = false" class="btn btn-primary btn-block">OK</ArgonButton>
            </DialogView>

            <!--      <SensorSelectionModal />-->
            <div>
              <div class="card flex justify-content-center">
                <DialogView v-model:visible="visible" modal header="Choose Sensors" :style="{ width: '25rem' }">
                  <span class="p-text-secondary block mb-5">Select sensors to get data from</span>
                  <div class="flex align-items-center gap-3 mb-5">
                    <input v-model="searchTerm" placeholder="Search sensors" class="form-control mb-3">
                    <!-- Multiple select options -->
                    <select multiple v-model="selectedSensors" class="form-select">
                      <option v-for="sensor in filteredSensors" :key="sensor.id" :value="sensor.unique_id">{{ sensor.name }}</option>
                    </select>
                  </div>
                  <div class="flex justify-content-end gap-2">
                    <Button type="button" class="btn btn-secondary mx-2" @click="visible = false">Close</Button>
                    <!--              <Button type="button" class="btn btn-primary">Save changes</Button>-->
                  </div>
                </DialogView>
              </div>
            </div>

            <div class="d-flex justify-content-end mb-3">
              <div class="d-flex flex-column">
                <label for="startDate" class="form-label">Start Date</label>
                <ArgonInput type="datetime-local" v-model="formData.startDate" label="Start Date" required/>
                <label for="endDate" class="form-label">End Date</label>
                <ArgonInput type="datetime-local" v-model="formData.endDate" label="End Date" required/>
              </div>

              <div class="d-flex flex-column mx-3">
                <label for="startDate" class="form-label">Data Types</label>
                <div class="form-check">
                  <input
                      id="temperature"
                      class="form-check-input"
                      type="checkbox"
                      value="temperature"
                      v-model="formData.dataTypes"
                  />
                  <label for="temperature" class="custom-control-label">
                    Temperature
                  </label>
                </div>
                <div class="form-check">
                  <input
                      id="humidity"
                      class="form-check-input"
                      type="checkbox"
                      value="humidity"
                      v-model="formData.dataTypes"
                  />
                  <label for="temperature" class="custom-control-label">
                    Humidity
                  </label>
                </div>
                <div class="form-check">
                  <input
                      id="pressure"
                      class="form-check-input"
                      type="checkbox"
                      value="pressure"
                      v-model="formData.dataTypes"
                  />
                  <label for="temperature" class="custom-control-label">
                    Pressure
                  </label>
                </div>
                <div class="form-check">
                  <input
                      id="co2"
                      class="form-check-input"
                      type="checkbox"
                      value="co2"
                      v-model="formData.dataTypes"
                  />
                  <label for="temperature" class="custom-control-label">
                    CO2
                  </label>
                </div>
              </div>

              <div class="d-flex flex-column mx-3 justify-content-center">
                <Button @click="visible = true" class="btn btn-primary btn-block">Choose Sensors</Button>
                <!-- Dropdown button for downloading data -->
                <div class="d-flex flex-column mx-3">
                  <Dropdown v-model="selectedFormat" :options="downloadFormats" optionLabel="label" optionValue="value" placeholder="Select Format"/>
                  <Button @click="downloadData" class="btn btn-primary btn-block">Download Data</Button>
                </div>
                <ArgonButton @click="fetchData" class="btn btn-primary btn-block">Fetch Data</ArgonButton>
              </div>
            </div>

            <!-- Data Table -->
            <div :class="{ 'opacity-50': isLoading }" class="table-responsive p-0">
              <div>
                <DataTable :value="sensorData" removable-sort :paginator="true" :rows="10" :rowsPerPageOptions="[5, 10, 20]">
                  <Column field="sensorId" header="Sensor Name" sortable>
                    <template #body="{ data }"> <!-- Done like this so we can get the friendly name instead of displaying ID -->
                      {{ getSensorName(data.sensor_id) }}
                    </template>
                  </Column>
                  <Column field="time" header="Time" sortable></Column>
                  <Column field="data_type" header="Data Type" sortable></Column>
<!--                  <Column field="unit" header="Unit"></Column>-->
                  <Column field="value" header="Value" sortable>
                    <template #body="{ data }">
                      {{ convertValue(data.data_type, data.value, useSettingStore().isMetricUnits) }} {{ getUnitLabel(data.data_type, useSettingStore().isMetricUnits) }}
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.opacity-50 {
  opacity: 0.5;
}
</style>