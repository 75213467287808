<script setup>
import { ref } from 'vue';
import { useAuthStore } from "@/store/AuthStore";
import { useAxios } from "@/plugins/axios";

const axios = useAxios();
const authStore = useAuthStore();

const sensorName = ref('');
const sensorLocation = ref('');
const enabled = ref(false);
const sensorTags = ref([]);
const isLoading = ref(false);

const successMessage = ref('');
const errors = ref({
  sensorName: '',
  sensorLocation: '',
  enabled: '',
  sensorTags: '',
});

const validateForm = () => {
  let valid = true;
  errors.value = {
    sensorName: '',
    sensorLocation: '',
    enabled: '',
    sensorTags: '',
  };

  if (!sensorName.value) {
    errors.value.sensorName = 'Sensor name is required';
    valid = false;
  } // Name is only mandatory field rn

  return valid;
};

// Function to reset the form
const resetForm = () => {
  sensorName.value = '';
  sensorLocation.value = '';
  enabled.value = false;
  sensorTags.value = [];
  errors.value = {
    sensorName: '',
    sensorLocation: '',
    enabled: '',
    sensorTags: '',
  };
  successMessage.value = '';
};

// Handle form
const createSensor = async () => {
  if (!validateForm()) {
    return;
  }
  isLoading.value = true;
  try {
    const response = await axios.post('/sensor/new', {
      name: sensorName.value,
      location: sensorLocation.value,
      enabled: enabled.value,
      tags: sensorTags.value,
    }, {
      headers: {
        'Authorization': `Bearer ${authStore.user_token}`
      }

    });
    console.log('Sensor created: ', response.data);
    resetForm();
    setTimeout(() => {
       // Reset the form after a delay
      isLoading.value = false;
    }, 1100);
    successMessage.value = 'Sensor created successfully!';
  } catch (error) {
    isLoading.value = false;
    console.error('Sensor creation failed: ', error);
  }
};
</script>

<template>

  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body"><h6 class="mb-0">New Sensor</h6>
          <p class="mb-0 text-sm">Add a new sensor</p>
          <hr class="my-3 horizontal dark">

          <!-- Success Message -->
          <transition name="fade" @leave="resetForm">
            <div v-if="successMessage" class="alert alert-info text-white alert-dismissible" role="alert">
              {{ successMessage }}
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </transition>

          <transition name="fade">
            <div v-if="isLoading" class="card loading-overlay d-flex justify-content-center align-items-center">
              <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </transition>

          <div :class="{ 'opacity-50': isLoading }">
            <label for="sensorName" class="form-label">Sensor Name <span class="required">*</span></label>
            <input id="sensorName" type="text" class="form-control" v-model="sensorName">
            <div v-if="errors.sensorName" class="text-danger">{{ errors.sensorName }}</div>

            <label for="sensorLocation" class="form-label mt-4">Sensor Location</label>
            <input id="sensorLocation" type="text" class="form-control" v-model="sensorLocation">
            <div v-if="errors.sensorLocation" class="text-danger">{{ errors.sensorLocation }}</div>

            <div class="mt-4 row">
              <div class="col-12 col-md-6">
                <div class="form-group"><label> Enabled? </label>
                  <p class="text-xs form-text text-muted ms-1">
                    If you want this sensor to not be enabled, don't tick
                  </p>
                  <div class="form-check form-switch ms-1">
                    <input id="enabled-switch" class="form-check-input"
                           type="checkbox" onclick=""
                           data-type="warning"
                           data-content="Once a sensor is made private , idk"
                           data-title="Warning" data-icon="ni ni-bell-55"
                           v-model="enabled"
                    >
                    <label class="form-check-label" for="enabled-switch"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-3 horizontal dark" />
          <div class="mt-4 d-flex justify-content-end">
            <button type="button" name="cancelBtn" class="m-0 btn btn-light"> Cancel</button>
<!--            <button type="button" name="submitAndNewBtn" class="m-0 btn btn-outline-success ms-2">Add Another</button>-->
            <button type="button" name="submitBtn" @click="createSensor" class="m-0 btn bg-gradient-success ms-2"> Create Sensor</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.opacity-50 {
  opacity: 0.5;
}
</style>