import { inject } from 'vue';
import axios from 'axios';
import { useAuthStore } from "@/store/AuthStore";
import NProgress from 'nprogress';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL + '/v1/', // CHange for production
    headers: {
        'Content-Type': 'application/json',
    },
});

const setupInterceptors = () => {
    const authStore = useAuthStore();
    axiosInstance.interceptors.request.use (
        (config) => {
            NProgress.start();
            const token = authStore.user_token;
            if (token) { // TODO : Add jwt verfication or wait until error?
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use (
        (response) => {
            NProgress.done();
            return response;
        },
        async (error) => {
            const originalReq = error.config;
            if (error.response.status === 401 && !originalReq._retry) {
                originalReq._retry = true;
                await authStore.refreshAccessToken();
                return axiosInstance(originalReq);
            }
            NProgress.done();
            return Promise.reject(error);
        }
    );
}

export const axiosPlugin = {
    install: (app) => {
        setupInterceptors();
        app.provide('axios', axiosInstance);
    },
};

export const useAxios = () => {
    const axios = inject('axios');
    if (!axios) {
        throw new Error('Axios instance not provided');
    }
    return axios;
};