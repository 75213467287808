<script setup>
import MiniStatisticsCard from "@/components/Dashboard/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/components/Dashboard/Charts/GradientLineChart.vue";
import Carousel from "./components/GetStartedCard.vue";
import CategoriesList from "./components/CategoriesList.vue";

import GB from "@/assets/img/icons/flags/GB.png";
import { onBeforeMount, onMounted, ref } from "vue";
import { useAxios } from "@/plugins/axios";

const axios = useAxios();

const sales = {
  britain: {
    country: "Great Britain",
    sales: "1,400",
    value: "£190,700",
    bounce: "23.44%",
    flag: GB,
  },
};

const onlineSensorCount = ref(0);
const offlineSensorCount = ref(0);
const totalSensorCount = ref(0);
const alertSensorCount = ref(0);

async function getStats() {
  try {
    const response = await axios.get('/sensors/stats');
      onlineSensorCount.value = response.data.total_online;
      offlineSensorCount.value = response.data.total_offline;
      totalSensorCount.value = response.data.total_count;
      alertSensorCount.value = response.data.total_alert;
  } catch (error) {
    console.error('Failed to fetch stats', error);
  }
}

const avgChartData = ref(Array(12).fill(0.0));
const chartLabels = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];
const isChartShowing = ref(false);

const fetchSensorAverages = async () => {
  try {
    const response = await axios.get('/sensors/averages');
    const data = response.data;

    const monthMap = {
      '01': 0, '02': 1, '03': 2, '04': 3,
      '05': 4, '06': 5, '07': 6, '08': 7,
      '09': 8, '10': 9, '11': 10, '12': 11
    };
    Object.keys(data).forEach((date) => {
      if (data[date] !== null) {
        const month = date.split('-')[1]; // Extract the month from the date
        avgChartData.value[monthMap[month]] = data[date];
      }
    });

    isChartShowing.value = true;
  } catch (error) {
    console.error('Failed to fetch sensor averages:', error);
  }
};

onBeforeMount(async() => {
  await fetchSensorAverages();
});

onMounted(async () => {
  await getStats();
  // await fetchSensorAverages();
});
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Sensors Online"
              :value="onlineSensorCount"
              description=""
              :icon="{
                component: 'fa fa-toggle-on',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Sensors with alerts"
              :value="alertSensorCount"
              description=""
              :icon="{
                component: 'fa fa-exclamation-circle',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Sensors offline"
              :value="offlineSensorCount"
              description=""
              :icon="{
                component: 'fa fa-toggle-off',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Sensors assigned"
              :value="totalSensorCount"
              description=""
              :icon="{
                component: 'fa fa-user-circle',
                background: 'bg-gradient-calid',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart
                  v-if="isChartShowing"
                id="chart-line"
                title="Sensors Overview"
                description="<i class='fa fa-arrow-up text-success'></i>
      <span class='font-weight-bold'>4°C hotter</span> this month"
                :chart="{
                  labels: chartLabels,
                  datasets: [
                    {
                      label: 'Average Temperature',
                      data: avgChartData,
                    },
                  ],
                }"
              />
              <Skeleton v-else />
            </div>
          </div>
          <div class="col-lg-5">
            <carousel />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-7 mb-lg-0 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">Sensors by Country</h6>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table align-items-center">
                  <tbody>
                    <tr v-for="(sale, index) in sales" :key="index">
                      <td class="w-30">
                        <div class="px-2 py-1 d-flex align-items-center">
                          <div>
                            <img :src="sale.flag" alt="Country flag" />
                          </div>
                          <div class="ms-4">
                            <p class="mb-0 text-xs font-weight-bold">
                              Country:
                            </p>
                            <h6 class="mb-0 text-sm">{{ sale.country }}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Sensors:</p>
                          <h6 class="mb-0 text-sm">{{ sale.sales }}</h6>
                        </div>
                      </td>
                      <td>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Cost:</p>
                          <h6 class="mb-0 text-sm">{{ sale.value }}</h6>
                        </div>
                      </td>
                      <td class="text-sm align-middle">
                        <div class="text-center col">
                          <p class="mb-0 text-xs font-weight-bold">Offline:</p>
                          <h6 class="mb-0 text-sm">{{ sale.bounce }}</h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <categories-list
              :categories="[
                {
                  icon: {
                    component: 'ni ni-mobile-button',
                    background: 'dark',
                  },
                  label: 'Sensors',
                  description: onlineSensorCount + ' online <strong>' + offlineSensorCount + ' offline</strong>',
                  url: '/sensor/manage'
                },
              ]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
