<script setup>
import { computed } from "vue";
import { useMainStore } from "@/store/MainStore.js";
import Sidenav from "./components/Navbars/SidenavBase.vue";
import Navbar from "@/components/Navbars/Topnav.vue";
import AppFooter from "@/components/Dashboard/Footer.vue";

const store = useMainStore();
const isNavFixed = computed(() => store.isNavFixed);
const darkMode = computed(() => store.darkMode);
const isAbsolute = computed(() => store.isAbsolute);
const showSidenav = computed(() => store.showSidenav);
const layout = computed(() => store.layout);
const showNavbar = computed(() => store.showNavbar);
const showFooter = false;

// Show dev mode banner
const devMode = process.env.NODE_ENV === "development";

const navClasses = computed(() => {
  return {
    "position-sticky bg-white left-auto top-2 z-index-sticky":
      isNavFixed.value && !darkMode.value,
    "position-sticky bg-default left-auto top-2 z-index-sticky":
      isNavFixed.value && darkMode.value,
    "position-absolute px-4 mx-0 w-100 z-index-2": isAbsolute.value,
    "px-0 mx-4": !isAbsolute.value,
  };
});
</script>
<template>
  <div
    v-show="layout === 'landing'"
    class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>

  <sidenav v-if="showSidenav" />

  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >

    <div class="mb-2 navbar bg-gradient-warning navbar-main navbar-expand-lg d-flex justify-content-center align-items-center p-4 mx-4 shadow-none border-radius-xl position-sticky shadow-blur mt-2 left-auto z-index-sticky"
    v-if="devMode">
      <a href="" class="p-0 m-0 text-white" id="user-verify-banner">Dev Mode</a>
    </div>
    <!-- nav -->

    <navbar :class="[navClasses]" v-if="showNavbar" v-slot="{ Component }">
<!--      <transition name="fade" mode="out-in">-->
        <component :is="Component" />
<!--      </transition>-->
    </navbar>

    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

<!--    Footer disabled currently -->
    <app-footer v-show="showFooter" />

<!--    <configurator-->
<!--      :toggle="toggleConfigurator"-->
<!--      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"-->
<!--    />-->
  </main>
</template>
