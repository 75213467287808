// store/SettingsStore.js
import { defineStore } from 'pinia';
import { useAxios } from "@/plugins/axios";

export const useSettingStore = defineStore({
    id: 'settings',
    state: () => ({
        darkMode: false,
        metricUnits: true,
        notifyByEmail: false,
        notifyBySMS: false,
        notifyByPush: false
    }),
    actions: {
        async fetchUserSettings() {
            const axios = useAxios(); // Axios must be imported here otherwise fails to get instance
            try {
                const response = await axios.get('/user/settings');
                this.darkMode = response.data.dark_mode;
                this.metricUnits = response.data.units === 'metric';
                this.notifyByEmail = response.data.notify_by_email;
                this.notifyBySMS = response.data.notify_by_sms;
                this.notifyByPush = response.data.notify_by_push;
            } catch (error) {
                console.error("Error fetching user settings", error);
            }
        },
        toggleDarkMode() {
            this.darkMode = !this.darkMode;
        },
        toggleMetricUnits() {
            this.metricUnits = !this.metricUnits;
        },
        toggleNotifyByEmail() {
            this.notifyByEmail = !this.notifyByEmail;
        },
        toggleNotifyBySMS() {
            this.notifyBySMS = !this.notifyBySMS;
        },
        toggleNotifyByPush() {
            this.notifyByPush = !this.notifyByPush;
        }
    },
    getters: {
        isDarkMode: (state) => state.darkMode,
        isMetricUnits: (state) => state.metricUnits,
        isNotifyByEmail: (state) => state.notifyByEmail,
        isNotifyBySMS: (state) => state.notifyBySMS,
        isNotifyByPush: (state) => state.notifyByPush
    }
});