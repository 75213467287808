<template>
  <div>
    <!-- Step progress bar -->
    <div class="row">
      <div class="col-12 col-lg-8 mx-auto mb-4">
        <div class="card">
          <div class="card-body">
            <div class="multisteps-form__progress">
              <button
                  v-for="(step, index) in steps"
                  :key="index"
                  :class="[
                  'multisteps-form__progress-btn',
                  { 'js-active': index === activeStep, 'disabled': step.disabled }
                ]"
                  type="button"
                  @click="!step.disabled && goToStep(index)"
                  :title="step.title"
                  :disabled="step.disabled"
              >
                <span>{{ step.title }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Form content -->
    <div class="row">
      <div class="col-12 col-lg-8 m-auto">
        <form class="multisteps-form__form">
          <!-- User Info -->
          <div v-if="activeStep === 0" class="card multisteps-form__panel p-3 border-radius-xl bg-white js-active" data-animation="FadeIn">
            <h5 class="font-weight-bolder mb-0">{{ steps[0].title }}</h5>
            <p class="mb-0 text-sm">{{ steps[0].description }}</p>
            <div class="multisteps-form__content">
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <label>First Name</label>
                  <div class="form-group multisteps-form__input">
                    <input v-model="formData.firstName" type="text" class="form-control form-control-default" placeholder="John">
                  </div>
                  <span v-if="errors.firstName" class="text-danger">{{ errors.firstName }}</span>
                </div>
                <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                  <label>Last Name</label>
                  <div class="form-group multisteps-form__input">
                    <input v-model="formData.lastName" type="text" class="form-control form-control-default" placeholder="Smith">
                  </div>
                  <span v-if="errors.lastName" class="text-danger">{{ errors.lastName }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <label>Company</label>
                  <div class="form-group multisteps-form__input">
                    <input v-model="formData.company" type="text" class="form-control form-control-default" placeholder="ZMIT">
                  </div>
                </div>
                <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                  <label>Email Address</label>
                  <div class="form-group multisteps-form__input">
                    <input v-model="formData.email" type="email" class="form-control form-control-default" placeholder="johndoe@gmail.com">
                  </div>
                  <span v-if="errors.email" class="text-danger">{{ errors.email }}</span>
                </div>
              </div>

              <div class="button-row d-flex mt-2">
                <button class="btn mb-0 bg-gradient-dark btn-md ms-auto" type="button" @click="validateStep(0) && nextStep()">Next</button>
              </div>
            </div>
          </div>

          <!-- Address -->
          <div v-if="activeStep === 1" class="card multisteps-form__panel p-3 border-radius-xl bg-white js-active" data-animation="FadeIn">
            <h5 class="font-weight-bolder">{{ steps[1].title }}</h5>
            <p class="mb-0 text-sm">{{ steps[1].description }}</p>
            <div class="multisteps-form__content">
              <div class="row mt-3">
                <div class="col-12">
                  <label>Address Line 1</label>
                  <div class="form-group multisteps-form__input">
                    <input v-model="formData.address1" type="text" class="form-control form-control-default" placeholder="1 West Road">
                  </div>
                  <span v-if="errors.address1" class="text-danger">{{ errors.address1 }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <label>Address Line 2</label>
                  <div class="form-group multisteps-form__input">
                    <input v-model="formData.address2" type="text" class="form-control form-control-default" placeholder="Example Town">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <label>City</label>
                  <div class="form-group multisteps-form__input">
                    <input v-model="formData.city" type="text" class="form-control form-control-default" placeholder="Placeholder City">
                  </div>
                  <span v-if="errors.city" class="text-danger">{{ errors.city }}</span>
                </div>
                <div class="col-6 col-sm-3 mt-3 mt-sm-0">
                  <label>County</label>
                  <div class="form-group multisteps-form__input">
                    <select v-model="formData.county" class="form-control">
                      <option value="Hertfordshire">Hertfordshire</option>
                      <option value="Essex">Essex</option>
                      <option value="Oxfordshire">Oxfordshire</option>
                    </select>
                  </div>
                </div>
                <div class="col-6 col-sm-3 mt-3 mt-sm-0">
                  <label>Postal Code</label>
                  <div class="form-group multisteps-form__input">
                    <input v-model="formData.postCode" type="text" class="form-control form-control-default" placeholder="AB12 3CD">
                  </div>
                  <span v-if="errors.postCode" class="text-danger">{{ errors.postCode }}</span>
                </div>
              </div>
              <div class="button-row d-flex mt-2">
                <button class="btn mb-0 bg-gradient-light btn-md" type="button" @click="prevStep">Previous</button>
                <button class="btn mb-0 bg-gradient-dark btn-md ms-auto" type="button" @click="validateStep(1) && nextStep()">Next</button>
              </div>
            </div>
          </div>

          <!-- Add a Sensor -->
          <div v-if="activeStep === 2" class="card multisteps-form__panel p-3 border-radius-xl bg-white js-active" data-animation="FadeIn">
            <h5 class="font-weight-bolder mb-0">{{ steps[2].title }}</h5>
            <p class="mb-0 text-sm">{{ steps[2].description }}</p>
            <div class="multisteps-form__content mt-4">
              <div class="row">
                <div class="col-12">
                  <label>Sensor Name</label>
                  <div class="form-group multisteps-form__input">
                    <input v-model="formData.sensorName" type="text" class="form-control form-control-default" placeholder="My first sensor">
                  </div>
                  <span v-if="errors.sensorName" class="text-danger">{{ errors.sensorName }}</span>
                </div>
                <div class="col-12">
                  <label>Sensor Location</label>
                  <div class="form-group multisteps-form__input">
                    <input v-model="formData.sensorLocation" type="text" class="form-control form-control-default" placeholder="ESP8266">
                  </div>
                  <span v-if="errors.sensorLocation" class="text-danger">{{ errors.sensorLocation }}</span>
                </div>
                <div class="col-12 mt-3">
                  <label>Data Types</label>
                  <div class="form-group multisteps-form__input">
                    <input v-model="formData.sensorDataTypes" type="text" class="form-control form-control-default" placeholder="Temperature">
                  </div>
                  <span v-if="errors.sensorDataTypes" class="text-danger">{{ errors.sensorDataTypes }}</span>
                </div>
              </div>
              <div class="button-row d-flex mt-4">
                <button class="btn mb-0 bg-gradient-light btn-md" type="button" @click="prevStep">Previous</button>
                <button class="btn mb-0 bg-gradient-dark btn-md ms-auto" type="button" @click="submitForm">Finish Setup</button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useAuthStore } from "@/store/AuthStore";
import { useAxios } from "@/plugins/axios";

const activeStep = ref(0);

const steps = [
  {title: 'User Info', description: 'We just need some more details about you', disabled: false},
  {title: 'Address', description: 'Provide your address information', disabled: false},
  {title: 'Add a sensor', description: 'Add sensor information'},
];

const axios = useAxios();
const authStore = useAuthStore();

const formData = ref({
  firstName: authStore.user.first_name,
  lastName: authStore.user.last_name,
  company: '',
  email: authStore.user.email,
  address1: '',
  address2: '',
  city: '',
  county: '',
  postCode: '',
  creditCard: '',
  expirationDate: '',
  cvv: '',
  sensorName: '',
  sensorLocation: '',
  sensorDataTypes: '',
});

const errors = ref({
  firstName: '',
  lastName: '',
  email: '',
  address1: '',
  city: '',
  postCode: '',
  sensorName: '',
  sensorLocation: '',
  sensorDataTypes: '',
});

const validateStep = (stepIndex) => {
  let isValid = true;

  // Clear previous errors
  for (const key in errors.value) {
    errors.value[key] = '';
  }

  if (stepIndex === 0) {
    if (!formData.value.firstName) {
      errors.value.firstName = 'First Name is required';
      isValid = false;
    }
    if (!formData.value.lastName) {
      errors.value.lastName = 'Last Name is required';
      isValid = false;
    }
    if (!formData.value.email) {
      errors.value.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.value.email)) {
      errors.value.email = 'Email is invalid';
      isValid = false;
    }
  } else if (stepIndex === 1) {
    if (!formData.value.address1) {
      errors.value.address1 = 'Address Line 1 is required';
      isValid = false;
    }
    if (!formData.value.city) {
      errors.value.city = 'City is required';
      isValid = false;
    }
    if (!formData.value.postCode) {
      errors.value.postCode = 'Postal Code is required';
      isValid = false;
    }
  } else if (stepIndex === 2) {
    if (!formData.value.sensorName) {
      errors.value.sensorName = 'Sensor Name is required';
      isValid = false;
    }
    if (!formData.value.sensorLocation) {
      errors.value.sensorLocation = 'Sensor Location is required';
      isValid = false;
    }
    if (!formData.value.sensorDataTypes) {
      errors.value.sensorDataTypes = 'Data Types are required';
      isValid = false;
    }
  }

  return isValid;
};

const goToStep = (stepIndex) => {
  if (validateStep(activeStep.value)) {
    activeStep.value = stepIndex;
    console.log('Current step:', steps[stepIndex].title);
  }
};

const nextStep = () => {
  if (validateStep(activeStep.value)) {
    if (activeStep.value < steps.length - 1) {
      activeStep.value += 1;
      console.log('Next step:', steps[activeStep.value].title);
    }
  }
};

const prevStep = () => {
  if (activeStep.value > 0) {
    activeStep.value -= 1;
    console.log('Previous step:', steps[activeStep.value].title);
  }
};

const submitForm = async () => {
  if (validateStep(activeStep.value)) {
    try {
      const addrResponse = await axios.post('/user/address/set', {
        address_line: formData.value.address1,
        city: formData.value.city,
        // county: formData.value.county,
        post_code: formData.value.postCode,
        country: 'UK',
      });
      const sensorResponse = await axios.post('/sensor/new', {
        name: formData.value.sensorName,
        location: formData.value.sensorLocation
      });
      // TODO : No longer show card for user
      console.log('Form submitted successfully:', addrResponse.data, sensorResponse.data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }
};
</script>

<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.text-danger {
  color: red;
}
</style>
