<script setup>
import { computed, ref } from "vue";
import { useMainStore } from "@/store/MainStore.js";
import { useRoute } from "vue-router";
import Breadcrumbs from "../Dashboard/Breadcrumbs.vue";
import { useAuthStore } from "@/store/AuthStore";
import Notifications from "@/components/Dashboard/Notifications.vue";

const showUserMenu = ref(false);
const store = useMainStore();
const authStore = useAuthStore();
const darkMode = computed(() => store.darkMode);

const route = useRoute();

const isAuthenticated = computed(() => authStore.isAuthenticated);
const userName = computed(() => authStore.getUser?.full_name);

const currentRouteName = computed(() => {
  return route.name;
});
const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];
  return dir.charAt(0).toUpperCase() + dir.slice(1);
});

const minimizeSidebar = () => store.sidebarMinimize();
// const toggleConfigurator = () => store.commit("toggleConfigurator");

const closeUserMenu = () => {
  setTimeout(() => {
    showUserMenu.value = false;
  }, 100);
};

</script>
<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
      />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center ms-md-auto"
        >
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item dropdown d-flex align-items-center">
            <a
                href="#"
                class="px-0 nav-link font-weight-bold opacity-8 text-dark"
                :class="[showUserMenu ? 'show' : '']"
                v-if="isAuthenticated"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="showUserMenu = !showUserMenu"
                @blur="closeUserMenu"
                id="userDropdownBtn"
            >

              <i class="fa fa-user me-sm-1 px-1" aria-hidden="true"></i>
              <span class="d-sm-inline d-none">{{ userName }}</span>
            </a>
            <router-link
              v-else
              :to="{ name: 'Signin' }"
              class="px-0 nav-link font-weight-bold"
              :class="darkMode ? 'text-white' : 'text-dark'"
            >
              <i class="fa fa-user me-sm-2"></i>
              <span class="d-sm-inline d-none">Sign In</span>
            </router-link>
            <ul
                class="px-1 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
                :class="showUserMenu ? 'show' : ''"
                aria-labelledby="userDropdownBtn"
            >
              <li class="mb-2">
                <router-link to="/account" class="dropdown-item border-radius-md ">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <i class="fa fa-user avatar avatar-sm me-3 text-dark"></i>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Account</span>
                      </h6>
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="mb-2">
                <router-link to="/logout" class="dropdown-item border-radius-md">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <i class="fa fa-sign-out avatar avatar-sm me-3 text-dark"></i>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Log Out</span>
                      </h6>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="minimizeSidebar"
              class="p-0 nav-link"
              :class="darkMode ? 'text-white' : 'text-dark'"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"
                   :class="darkMode ? 'bg-white' : 'bg-dark'"></i>
                <i class="sidenav-toggler-line"
                   :class="darkMode ? 'bg-white' : 'bg-dark'"></i>
                <i class="sidenav-toggler-line"
                   :class="darkMode ? 'bg-white' : 'bg-dark'"></i>
              </div>
            </a>
          </li>
          <li class="px-2 nav-item d-flex align-items-center" style="display: none;">

          </li>
          <Notifications />
        </ul>
      </div>
    </div>
  </nav>
</template>
