import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import { createPinia } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import { axiosPlugin } from "@/plugins/axios";
import PrimeVue from 'primevue/config';
import Toast from 'primevue/toast';
import Dialog from 'primevue/dialog';
import Tooltip from 'primevue/tooltip';
import 'primevue/resources/themes/aura-light-green/theme.css';
import 'nprogress/nprogress.css';
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
pinia.use(piniaPluginPersistedState);
app.use(router);
app.use(ArgonDashboard);
app.use(axiosPlugin);
app.use(PrimeVue, {ripple: true});
app.use(ToastService);
app.use(ConfirmationService);

app.component('Toast', Toast)
app.component('DialogView', Dialog);
app.component('InputText', require('primevue/inputtext').default);
app.directive('tooltip', Tooltip);


app.mount("#app");
