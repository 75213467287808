<script setup>

import {useAxios} from "@/plugins/axios";
import {onMounted, ref} from "vue";
import ArgonButton from "@/components/Argon/ArgonButton.vue";
import UAParser from "ua-parser-js";
import Button from 'primevue/button';
import { useToast } from "primevue/usetoast";

const axios = useAxios();
const toast = useToast();

const loginAttempts = ref([]);

onMounted(async () => {
  try {
    const response = await axios.get('/user/security/login-attempts');
    loginAttempts.value = response.data;
  } catch (error) {
    console.error('Error fetching login attempts:', error);
  }
});

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString();
};

const showToast = (severity, summary, detail) => {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 3000, // Optional: Override default life time
  });
};

const parser = new UAParser();
const formatUserAgent = (userAgent) => {
  return parser.setUA(userAgent).getResult();
};

const revokeToken = async (attemptId) => {
  try {
    const response = await axios.post('/user/security/revoke', { id: attemptId });
    if (response.status === 200) {
      // Update login attempts after successful revocation
      loginAttempts.value = loginAttempts.value.filter((attempt) => attempt.id !== attemptId);
      showToast('success', 'Success', response.data.message);
      console.log("Access revoked successfully");
    } else {
      console.error("Failed to revoke token");
    }

  } catch (error) {
    showToast('error', 'Error', 'Failed to revoke access');
    console.error('Error revoking token:', error);
  }
};

</script>
<template>
  <div class="card mb-4">
    <div class="p-toast-container">
      <Toast ref="toast" :life="5000"></Toast>
    </div>
    <div class="card-header pb-0 px-3">
      <div class="row">
        <div class="col-md-6">
          <h6 class="mb-0">Recent Logins</h6>
        </div>
        <div class="col-md-6 d-flex justify-content-end align-items-center">
          <i class="far fa-calendar-alt me-2" aria-hidden="true"></i>
          <small>Last 5 logins</small>
        </div>
      </div>
    </div>
    <div class="card-body pt-4 p-3">
      <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
        Successful
      </h6>
      <ul class="list-group">
        <li
            v-for="(attempt, index) in loginAttempts"
            :key="index"
            class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex align-items-center">
            <argon-button
                :color="attempt.successful ? (attempt.revoked ? 'danger' : (attempt.verified ? 'success' : 'primary')) : 'danger'"
                variant="outline"
                size="sm"
                class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
                disabled="true"
            >
              <i class="fa" :class="attempt.successful ? (attempt.revoked ? 'fa-times' : (attempt.verified ? 'fa-check' : 'fa-spinner')) : 'fa-times'" aria-hidden="true"></i>
            </argon-button>
            <div class="d-flex flex-column">
              <h5 class="mb-1 text-dark text-sm">
                {{ formatUserAgent(attempt.user_agent).browser.name }} {{ formatUserAgent(attempt.user_agent).browser.version }} on
                {{ formatUserAgent(attempt.user_agent).os.name }}
              </h5>
              <h5 class="mb-2 text-dark text-xs">{{ attempt.city }}<span v-if="attempt.city !== 'Localhost'">, {{ attempt.region }}</span> </h5>
              <span class="text-xs">{{ formatDate(attempt.timestamp.date) }}</span>
            </div>
          </div>
          <div
              class="d-flex align-items-center text-gradient text-sm font-weight-bold"
              :class="attempt.successful ? (attempt.revoked ? 'text-danger' : (attempt.verified ? 'text-success' : 'text-primary')) : 'text-danger'"
          >
            {{ attempt.successful ? (attempt.revoked ? 'Revoked' : (attempt.verified ? 'Successful' : 'Pending')) : 'Failed' }}
          </div>
<!--          <Button label="Revoke" severity="danger" outlined @click="revokeToken(attempt.id)" />-->
          <Button v-if="attempt.successful && attempt.verified && !attempt.revoked" v-tooltip="{ value: 'Revoke Access', showDelay: 300, hideDelay: 300 }" icon="fa fa-user-slash" severity="danger" outlined @click="revokeToken(attempt.id)" />
        </li>
      </ul>
    </div>
  </div>
</template>
