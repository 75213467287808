<script setup>
import { ref, onBeforeMount, onMounted, onBeforeUnmount } from "vue";
import { useMainStore } from "@/store/MainStore.js";

import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonInput from "@/components/Argon/ArgonInput.vue";
import ArgonButton from "@/components/Argon/ArgonButton.vue";
import { useAuthStore } from "@/store/AuthStore.js";
import SettingsToggleCard from "@/views/components/SettingsToggleCard.vue";
import { useAxios } from "@/plugins/axios";
import LoginAttemptList from "@/views/Account/LoginAttemptList.vue";
// import ProfileCard from "@/views/components/ProfileCard.vue";

const body = document.getElementsByTagName("body")[0];
const showButtons = false;

const axios = useAxios();
const store = useMainStore();
const authStore = useAuthStore();
let user = authStore.getUser;
let profilePicUrl = "https://avatar.oxro.io/avatar.svg?name=" + user.full_name;
const address = ref({
  address_line: "",
  city: "",
  country: "",
  post_code: "",
});

const successMessages = ref([]);

const removeSuccess = (index) => {
  successMessages.value.splice(index, 1);
};

async function getAddress() {
  try {
    const response = await axios.get("/user/address");
    address.value = response.data;
  } catch (error) {
    console.log (error);
  }
}

// Submit form from button
const submitForm = async () => {
  try {
    const response = await axios.post('/user/address/set', address.value);
    successMessages.value.push('Address updated successfully!');
    setTimeout(() => {
      // Remove the success message after 10 seconds
      successMessages.value.shift();
    }, 10000);

    const response2 = await axios.post('/user/info/set', user.value);
    successMessages.value.push('User updated successfully');
    console.log(response, response2);
  } catch (error) {
   console.log(error);
  }
}

onMounted(() => {
  store.isAbsolute = true;
  setNavPills();
  setTooltip();
  authStore.getUserInfo();
});
onBeforeMount(() => {
  store.imageLayout = "profile-overview";
  store.showNavbar = false;
  store.showFooter = true;
  store.hideConfigButton = true;
  getAddress();
  body.classList.add("profile-overview");
});
onBeforeUnmount(() => {
  store.isAbsolute = false;
  store.imageLayout = "default";
  store.showNavbar = true;
  store.showFooter = true;
  store.hideConfigButton = false;
  body.classList.remove("profile-overview");
});
</script>
<template>
  <main>
    <div class="container-fluid">
      <div
        class="page-header min-height-300"
        style="margin-right: -24px;margin-left: -34%;"
      >
        <span class="mask bg-gradient-calid opacity-6"></span>
      </div>
      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <img
                  :src="profilePicUrl"
                  alt="profile_image"
                  class="shadow-sm w-100 border-radius-lg"
                />
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{ user.full_name }}</h5>
                <p class="mb-0 font-weight-bold text-sm">Developer</p>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0" v-if="showButtons">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="px-0 py-1 mb-0 nav-link active"
                      data-bs-toggle="tab"
                      href="javascript:;"
                      role="tab"
                      aria-selected="true"
                    >
                      <svg
                        class="text-dark"
                        width="16px"
                        height="16px"
                        viewBox="0 0 42 42"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-2319.000000, -291.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(603.000000, 0.000000)">
                                <path
                                  class="color-background"
                                  d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                                />
                                <path
                                  class="color-background"
                                  d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                                  opacity="0.7"
                                />
                                <path
                                  class="color-background"
                                  d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                                  opacity="0.7"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span class="ms-1">Profile</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <h5 class="mb-0">Edit Profile</h5>
              </div>
            </div>
            <div class="card-body">
              <!-- Success Message -->
              <transition-group name="fade" tag="div">
                <div v-for="(message, index) in successMessages" :key="index" class="alert alert-info text-white alert-dismissible" role="alert">
                  {{ message }}
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="removeSuccess(index)">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </transition-group>

              <p class="text-uppercase text-sm">User Information</p>
              <div class="row">

                <div class="col-md-6">
                  <label for="input-firstname" class="form-control-label">First name</label>
                  <input class="form-control" type="text" :value="user.first_name" id="input-firstname" />
                </div>
                <div class="col-md-6">
                  <label for="input-lastname" class="form-control-label">Last name</label>
                  <argon-input type="text" :model-value="user.last_name" id="input-lastname" />
                </div>
                <div class="col-md-12">
                  <label for="input-email" class="form-control-label">Email address</label>
                  <argon-input type="email" :model-value="user.email" id="input-email" />
                </div>
              </div>
              <hr class="horizontal dark" />
              <p class="text-uppercase text-sm">Address Information</p>
              <div class="row">
                <div class="col-md-12">
                  <label for="input-addr" class="form-control-label">Address</label>
                  <argon-input type="text" id="input-addr" v-model="address.address_line" />
                </div>
                <div class="col-md-4">
                  <label for="input-city" class="form-control-label">City</label>
                  <argon-input type="text" value="test" id="input-city" v-model="address.city" />
                </div>
                <div class="col-md-4">
                  <label for="input-country" class="form-control-label">Country</label>
                  <argon-input type="text" value="UK" id="input-country" v-model="address.country" />
                </div>
                <div class="col-md-4">
                  <label for="input-postcode" class="form-control-label">Post code</label>
                  <argon-input type="text" model-value="EN1" id="input-postcode" v-model="address.post_code"/>
                </div>
              </div>
              <hr class="horizontal dark" />
              <argon-button @click="submitForm">Save Changes</argon-button>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-3">
          <SettingsToggleCard/>
        </div>
        <div class="col-md-4 mt-3">
          <LoginAttemptList />
          <!-- TODO : Security card-->
          <div class="card">
            <div class="card-header pb-0 p-3">
              <h6 class="mb-0">Security</h6>
            </div>
            <div class="card-body p-3">
              <hr class="horizontal dark" />
              <div class="row mt-2">
                <div class="col-lg-8 col-12 actions text-end ms-auto d-i">
                  <ArgonButton type="button" class="btn-danger mb-2">Cancel</ArgonButton>
                  <ArgonButton type="button" style="margin-left: 0.5em;" class="mb-2" @click="submitForm()">Save Changes</ArgonButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
